import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Logo = ({ isWhite, className }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "logos/SRA-logo-new-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 236
            placeholder: TRACED_SVG
          )
        }
      }
      logoWhite: file(relativePath: { eq: "logos/SRA-logo-new-1-white.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 134
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const imageLogo = getImage(data.logo)
  const imageWhite = getImage(data.logoWhite)

  return (
    <GatsbyImage image={isWhite ? imageWhite : imageLogo} alt='SRA Admin logo' className={className} />
  )
}

Logo.propTypes = {
  isWhite: PropTypes.bool,
  className: PropTypes.string,
}

export default Logo