import React from 'react';
import { Link } from 'gatsby'

import SignInWrapper from '../components/Containers/SignInWrapper';
import Layout from '../components/layout';
import SignInForm from '../components/SignIn';
import LandingBg from '../components/Sections/LandingBg';
import HeadData from "../data/HeadData"

const SignInPage = () => (
  <div className="grid grid-cols-1 xl:grid-cols-3">
    <SignInWrapper>
      <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
        Marketing Portal
      </h1>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600">
        Don't have an account? <Link to='/request-access' className='underline text-gray-500 hover:text-gray-600'>Request Access</Link>
      </p>

      <div className='w-full max-w-sm px-0 md:px-6'>
        <SignInForm />
      </div>
    </SignInWrapper>

    <div className="hidden xl:block xl:col-span-2">
      <LandingBg />
    </div>
  </div>
);

const SignIn = () => (
  <Layout>
    <HeadData title='Sign In' />
    <SignInPage />
  </Layout>
)

export default SignIn
